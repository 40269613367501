import {Box, Container, Grid, Typography} from "@mui/material";
import Head from "next/head";
import * as React from "react";
import Link from "next/link";
import Image from "next/image";


export default function Home() {
    return (
        <div style={{backgroundColor: "#06101f"}}>
            <Head>
                <title>Adults Bookmarks</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
                <meta name="robots"/>
                <meta name="description"
                      content="Your favorite adult bookmarks in one place. Pricing of the best porn sites, best instagram girls, best instagram sexy reels, best twitter porn pages"/>
            </Head>
            <Box>
                <Box sx={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${"/images/background.jpg"})`,
                    height: "600px",
                    // height: "100vh",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: "2rem",
                }}>
                    <Typography variant="h1" fontSize="4rem">
                        Your adult bookmarks
                    </Typography>
                </Box>
            </Box>

            <Container style={{color: "white", padding: 0, marginTop: "50px"}}>
                <Grid container
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="center">

                    <Grid item xs={12} sm={4} md={4} style={{height: "270px", padding: 5}}>
                        <Link href={"/porn-sites"}>
                            <div style={{cursor: "pointer"}}>
                                <Image src="icons/xxx.png" width="50" height="50" alt="porn-websites"/>
                                <br/>
                                <h2 style={{color: "rgb(255, 204, 23)", marginBottom: 0}}>Best Porn Websites Prices</h2>
                                <br/>
                                <Typography variant="body2" color="white">
                                    Stay informed and compare prices from the top porn websites with ease.
                                    Enjoy hassle-free shopping and never miss a sale or promotion on your favorite porn platform.
                                    <br/>
                                    From hot milfs to sexy teens, we got you covered!
                                </Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} style={{height: "270px", padding: 5}}>
                        <Link href={"/instagram-girls"}>
                            <div style={{cursor: "pointer"}}>
                                <Image src="icons/instagram.png" width="50" height="50" alt="instagram-girls"/>
                                <br/>
                                <h2 style={{color: "rgb(255, 204, 23)", marginBottom: 0}}>Sexy Instagram girls</h2>
                                <br/>
                                <Typography variant="body2" color="white">
                                    Find out the best and most active cute girls and sexy reals on instagram!
                                </Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} style={{height: "270px", padding: 5}}>
                        <Link href={"/twitter-porn"}>
                            <div style={{cursor: "pointer"}}>
                                <Image src="icons/twitter.png" width="50" height="50" alt="twitter-porn"/>
                                <br/>
                                <h2 style={{color: "rgb(255, 204, 23)", marginBottom: 0}}>Twitter Porn</h2>
                                <br/>
                                <Typography variant="body2" color="white">
                                    Do you want some Porn on your twitter feed? Check the best accounts with us!
                                </Typography>
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} style={{height: "270px", padding: 5}}>
                        <Link href={"/free-onlyfans"}>
                            <div style={{cursor: "pointer"}}>
                                <Image src="icons/onlyfans.png" width="75" height="50" alt="free-onlyfans"/>
                                <br/>
                                <h2 style={{color: "rgb(255, 204, 23)", marginBottom: 0}}>Free OnlyFans</h2>
                                <br/>
                                <Typography variant="body2" color="white">
                                    Do you want to check some hot onlyfans girls for free? Check our list
                                </Typography>
                            </div>
                        </Link>
                    </Grid>
                </Grid>

            </Container>
            <Container maxWidth="lg" sx={{
                paddingTop: "50px",
            }}>


            </Container>
        </div>
    )
}
